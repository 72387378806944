<template>
  <v-card>
    <div>
      <h5 class="ma-3">
        Information<v-icon class="ma-1" size="15"
          >mdi-information-outline</v-icon
        >
      </h5>
    </div>
    <div
      style="height: 220px; width: 500px; margin: 5px; border: 2px solid red"
    >
      Graph
    </div>
  </v-card>
</template>

<script>
export default {
  name: "GraphSpace"
};
</script>

<style scoped></style>
